/* eslint-disable */
import Vue from 'vue';

const MyPlugin = {};

MyPlugin.install = function vuePlugin(App) {
  /* Router-Controller */
  App.mixin({
      methods: {
        $view (path)  {
            if (this.$route.path !== path) {
              this.$router.push({ path });
            }
          }
      }
  })
};

Vue.use(MyPlugin);

export default MyPlugin;
