<template>
  <v-app>
    <v-app-bar app color="white">
      <img
      style="cursor: pointer;"
      @click="$view('/')"
      @keydown="$view('/')"
      src="@/assets/logo.png" alt="logo" width="45px" height="auto" />
      <v-toolbar-title @click="$view('/')" class="ml-3" style="font-size: 40px; cursor: pointer;">
        <strong>Ablaze</strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="$view('/calculator')"> Calculator </v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
