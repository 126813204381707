<template>
   <div>
      <v-parallax
         dark
         src="https://dufeut.s3.us-east-2.amazonaws.com/media/parallax-2.png"
         >
         <v-row
            align="center"
            justify="center"
            >
            <v-col
               class="text-center"
               cols="12"
               >
               <h1 class="text-h1 font-weight-thin mb-4">
                  Welcome to <strong>Ablaze</strong>!
               </h1>
               <h1 class="subheading">
                  Quality is always the result
                  of intelligent effort.
               </h1>
            </v-col>
         </v-row>
      </v-parallax>

         <div style="width: 95vw; margin-left: auto; margin-right: auto;">
         <br>
         <v-row>
            <v-col class="text-center">
               <h1>Business Sample Pages</h1>
            </v-col>
            <v-col
               v-for="card in businessCards"
               :key="card.title"
               :cols="card.flex"
               >
               <v-card>
                  <v-img
                     style="cursor: pointer; border: solid 2px red;"
                     @click="openLink(card.link)"
                     :src="card.src"
                     class="white--text align-end elevation-16"
                     gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                     height="300px"
                     >
                     <v-card-title v-text="card.title"></v-card-title>
                  </v-img>
               </v-card>
            </v-col>
         </v-row>

         <br><br><br>

         <v-row>
            <v-col class="text-center">
               <h1>E-Commerce Sample Pages</h1>
            </v-col>
            <v-col
               v-for="card in ecommerceCards"
               :key="card.title"
               :cols="card.flex"
               >
               <v-card>
                  <v-img
                     style="cursor: pointer; border: solid 2px red;"
                     @click="openLink(card.link)"
                     :src="card.src"
                     class="white--text align-end elevation-16"
                     gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                     height="300px"
                     >
                     <v-card-title v-text="card.title"></v-card-title>
                  </v-img>
               </v-card>
            </v-col>
         </v-row>

         <br><br><br>

         <v-row>
            <v-col class="text-center">
               <h1>Portfolio Sample Pages</h1>
            </v-col>
            <v-col
               v-for="card in portfolioCards"
               :key="card.title"
               :cols="card.flex"
               >
               <v-card>
                  <v-img
                     style="cursor: pointer; border: solid 2px red;"
                     @click="openLink(card.link)"
                     :src="card.src"
                     class="white--text align-end elevation-16"
                     gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                     height="300px"
                     >
                     <v-card-title v-text="card.title"></v-card-title>
                  </v-img>
               </v-card>
            </v-col>
         </v-row>

         <br><br><br>

         <v-row>
            <v-col class="text-center">
               <h1>Personal & Blog Sample Pages</h1>
            </v-col>
            <v-col
               v-for="card in blogCards"
               :key="card.title"
               :cols="card.flex"
               >
               <v-card>
                  <v-img
                     style="cursor: pointer; border: solid 2px red;"
                     @click="openLink(card.link)"
                     :src="card.src"
                     class="white--text align-end elevation-16"
                     gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                     height="300px"
                     >
                     <v-card-title v-text="card.title"></v-card-title>
                  </v-img>
               </v-card>
            </v-col>
         </v-row>

         <div style="min-height: 80px;"></div>
        </div>

   </div>
</template>

<script>

export default {
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    },
  },
  data() {
    return {
      businessCards: [
        {
          title: 'Business 1', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/biz1-1024x440.png', link: 'https://biz1.wp.getablaze.com/', flex: 12,
        },
        {
          title: 'Business 2', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/biz2-1024x440.png', link: 'https://biz2.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Business 3', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/biz3-1024x440.png', link: 'https://biz3.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Business 4', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/biz4-1024x445.png', link: 'https://biz4.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Business 5', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/biz5-1024x455.png', link: 'https://biz5.wp.getablaze.com/', flex: 6,
        },
      ],
      ecommerceCards: [
        {
          title: 'E-Commerce 1', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/store1-1024x440.png', link: 'https://store1.wp.getablaze.com/', flex: 12,
        },
        {
          title: 'E-Commerce 2', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/store2-1024x440.png', link: 'https://store2.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'E-Commerce 3', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/store3-1024x440.png', link: 'https://store3.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'E-Commerce 4', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/store4-1024x440.png', link: 'https://store4.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'E-Commerce 5', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/store5-1024x440.png', link: 'https://store5.wp.getablaze.com/', flex: 6,
        },
      ],
      portfolioCards: [
        {
          title: 'Portfolio 1', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/portfolio2-1024x440.png', link: 'https://portfolio2.wp.getablaze.com/', flex: 12,
        },
        {
          title: 'Portfolio 2', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/portfolio1-1024x440.png', link: 'https://portfolio1.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Portfolio 3', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/portfolio3-1024x440.png', link: 'https://portfolio3.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Portfolio 4', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/portfolio4-1024x440.png', link: 'https://portfolio4.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Portfolio 5', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/portfolio5-1024x440.png', link: 'https://portfolio5.wp.getablaze.com/', flex: 6,
        },
      ],
      blogCards: [
        {
          title: 'Blog 1', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/blog1-1024x440.png', link: 'https://blog1.wp.getablaze.com/', flex: 12,
        },
        {
          title: 'Blog 2', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/blog2-1024x440.png', link: 'https://blog2.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Blog 3', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/blog3-1024x440.png', link: 'https://blog3.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Blog 4', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/blog4-1024x440.png', link: 'https://blog4.wp.getablaze.com/', flex: 6,
        },
        {
          title: 'Blog 5', src: 'https://dufeut.s3.us-east-2.amazonaws.com/media/blog5-1024x440.png', link: 'https://blog5.wp.getablaze.com/', flex: 6,
        },
      ],
    };
  },
};

</script>
