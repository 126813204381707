<template>
  <HomeComponent />
</template>

<script>
import HomeComponent from '../components/HomeComponent.vue';

export default {
  name: 'HomeView',

  components: {
    HomeComponent,
  },
};
</script>
